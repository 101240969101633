import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import OptionsForSelect from '../../OptionsForSelect';
import ContextMenuContainer from './ContextMenuContainer';
import AuthenticityTokenContext from './AuthenticityTokenContext';

import DeleteButton from '../../DeleteButton';

DashboardItem.propTypes = {
  link: PropTypes.object.isRequired,

  shareOptions: PropTypes.array,
  canDelete: PropTypes.bool,
  canShare: PropTypes.bool,
  canCopy: PropTypes.bool,
  canRevokeAccess: PropTypes.bool,
  deleteDashboardLink: PropTypes.func,
  canEdit: PropTypes.bool,
  surveyId: PropTypes.number,
  editModeUrl: PropTypes.string,
};

/**
 * One item (row) on the sidebar list
 * @param { Object } props
 * @return { DashboardItem }
 **/
function DashboardItem(props) {
  const [renderContextMenuTrigger, setRenderContextMenuTrigger] = React.useState(false);
  const [showCopyModal, setShowCopyModal] = React.useState(false);
  const [showShareModal, setShowShareModal] = React.useState(false);

  const dashboardId = props.link.dashboardId;
  const authenticityToken = React.useContext(AuthenticityTokenContext);

  /**
   * A modal dialogue for specifying users to share a report with
   * @return { Modal }
   **/
  function CopyModal() {
    return (
      <Modal
        style={{opacity: 1}}
        show={showCopyModal}
        onHide={() => {
          setShowCopyModal(false);
        }}
        centered
        className='qrvey-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title as='h1'>
            Copy &quot;{props.link.text}&quot;
          </Modal.Title>
        </Modal.Header>

        <form
          id={`qrvey_dashboard_clone_form_${dashboardId}`}
          action='/qrvey/clone_dashboard'
          method='POST'
        >
          <Modal.Body>
            <input
              type='hidden'
              name='authenticity_token'
              value={authenticityToken}
            />
            <input
              type='hidden'
              name='qrvey_dashboard_id'
              value={dashboardId}
            />
            {
              props.surveyId ?
                <input
                  type='hidden'
                  name='survey_id'
                  value={props.surveyId}
                /> : null
            }
            <label htmlFor='qrvey_dashboard_name'>Name:</label>
            <input
              id='qrvey_dashboard_name'
              name='qrvey_dashboard_name'
              defaultValue={`Copy of ${props.link.text}`}
            />
          </Modal.Body>
          <Modal.Footer>
            <input
              className='pi-primary-button'
              type='submit'
              value='Copy'
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  /**
   * A modal dialogue for specifying users to share a report with
   * @return { Modal }
   **/
  function ShareModal() {
    const [peopleSelected, setPeopleSelected] = React.useState([]);
    const [sharedWith, setSharedWith] = React.useState(() => {
      if (props.shareOptions === undefined) {
        return [];
      } else {
        return props.shareOptions.filter((option) => option.shared);
      }
    });

    const peopleNotSharedWith = () => {
      const selectedUserIds = peopleSelected.map((person) => person.userId);

      return props.shareOptions.filter((option) => {
        return !selectedUserIds.includes(option.userId) && !option.shared;
      }).map((option) => {
        return {
          label: option.userName,
          value: option.userId,
        };
      });
    };

    const accessLevelOptions = [
      {value: 0, label: 'Read'},
    ];

    if (props.canEdit) {
      accessLevelOptions.push({value: 1, label: 'Edit'});
    }

    const ListOfPeopleContainer = () => {
      /**
       * Mark a user as having no permission to access this report
       * @param { number } userId
       **/
      function markAsRevoked(userId) {
        const newSharedWith = [...sharedWith];
        const userToChange = newSharedWith.find((option) => option.userId == userId);
        userToChange.permissions = '';

        setSharedWith(newSharedWith);
      }

      return (
        <div>
          <input
            type='hidden'
            name='_method'
            value='PUT'
          />
          <input
            type='hidden'
            name='authenticity_token'
            value={authenticityToken}
          />
          <input
            type='hidden'
            name='qrvey_dashboard_id'
            value={dashboardId}
          />
          <table className='access-table'>
            <thead>
              <tr>
                <th>People with access</th>
                <th>Permissions</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                sharedWith.map((option, i) => {
                  return (
                    <tr
                      key={option.userId}
                      className={option.permissions === '' ? 'revoked' : ''}
                    >
                      <td>
                        {option.userName}
                        <input
                          type='hidden'
                          name={`users[][id]`}
                          value={option.userId}
                        />
                      </td>
                      <td>
                        {
                          option.permissions !== '' ?
                            <select
                              name={`users[][permissions]`}
                              defaultValue={option.permissions.toString()}
                              onChange={(e) => {
                                const newSharedWith = [...sharedWith];
                                const userToChange = newSharedWith.find((shareOption) => shareOption.userId == option.userId);
                                const newPermission = e.target.value;

                                userToChange.permissions = newPermission;
                                setSharedWith(newSharedWith);
                              }}
                            >
                              <OptionsForSelect options={accessLevelOptions} />
                            </select> : <>
                              <span>removal pending</span>
                              <input
                                type='hidden'
                                name={`users[][permissions]`}
                                value=""
                              />
                            </>
                        }
                      </td>
                      <td>
                        {
                          props.canRevokeAccess && option.permissions !== '' ?
                            <DeleteButton
                              onClick={(e) => markAsRevoked(option.userId)}
                            /> : null
                        }
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      );
    };

    const cancelShare = () => {
      setPeopleSelected([]);
    };

    const ShareIndexPanel = () => {
      return (
        <form
          id={`qrvey_dashboard_share_form_${dashboardId}`}
          action='/qrvey/change_dashboard_permissions'
          method='POST'
        >
          <Modal.Body className='audit-modal'>
            <Select
              isMulti
              options={peopleNotSharedWith()}
              className='basic-multi-select tag-selector'
              classNamePrefix='select'
              onChange={(selectedPeople) => {
                setPeopleSelected(selectedPeople);
              }}
              value={peopleSelected}
              placeholder='Add people'
            />
            {
              sharedWith.length > 0 ?
                <ListOfPeopleContainer /> : null
            }
          </Modal.Body>
          <Modal.Footer>
            <div className='actions-container'>
              <input
                className='pi-primary-button'
                type='submit'
                value='Save'
              />
              <button
                type='button'
                className='pi-secondary-button'
                onClick={() => setShowShareModal(false)}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </form>
      );
    };

    const ShareWithManyPanel = () => {
      return (
        <form
          id={`qrvey_dashboard_share_form_${dashboardId}`}
          action='/qrvey/share_dashboard'
          method='POST'
        >
          <Modal.Body className='audit-modal'>
            <div className='user-selection-container'>
              <Select
                isMulti
                options={peopleNotSharedWith()}
                className='basic-multi-select user-selector'
                classNamePrefix='select'
                onChange={(selectedPeople) => {
                  setPeopleSelected(selectedPeople);
                }}
                value={peopleSelected}
                placeholder='Add people'
                name='user_ids_to_share_with[]'
              />
              <input
                type='hidden'
                name='_method'
                value='PUT'
              />
              <input
                type='hidden'
                name='authenticity_token'
                value={authenticityToken}
              />
              <input
                type='hidden'
                name='qrvey_dashboard_id'
                value={dashboardId}
              />
              <select name='permissions'>
                <OptionsForSelect options={accessLevelOptions} />
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='actions-container'>
              <input
                className='pi-primary-button'
                type='submit'
                value='Share'
              />
              <button
                className='pi-secondary-button'
                onClick={() => cancelShare()}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </form>
      );
    };

    return (
      <Modal
        style={{opacity: 1}}
        show={showShareModal}
        onHide={() => {
          setShowShareModal(false);
        }}
        centered
        className='qrvey-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title as='h1'>
            Share &quot;{props.link.text}&quot;
          </Modal.Title>
        </Modal.Header>

        {
          peopleSelected.length === 0 ?
            <ShareIndexPanel /> :
            <ShareWithManyPanel />
        }
      </Modal>
    );
  }

  let className = 'qrvey-list-item';

  if (props.link.current) {
    className = `${className} current`;
  }

  if (props.link.additionalClasses) {
    className = `${className} ${props.link.additionalClasses}`;
  }

  const labelLengthLimit = 20;
  const trimmedLinkText = props.link.text.length > labelLengthLimit ?
    `${props.link.text.substring(0, labelLengthLimit)}...` :
    props.link.text;

  return (
    <li
      className={className}
      key={props.link.text}
    >
      <div
        className='dashboard-item-container'
        onMouseEnter={() => setRenderContextMenuTrigger(true)}
        onMouseLeave={() => setRenderContextMenuTrigger(false)}
      >
        <a
          className='qrvey-dashboard-link'
          href={props.link.url}
          title={props.link.text}
        >
          {trimmedLinkText}
        </a>
        {
          (props.canCopy || props.canDelete || props.canShare) ?
            <ContextMenuContainer
              dashboardId={dashboardId}
              dashboardName={props.link.text}
              renderContextMenuTrigger={renderContextMenuTrigger}
              canDelete={props.canDelete}
              canShare={props.canShare}
              canCopy={props.canCopy}
              deleteDashboardLink={props.deleteDashboardLink}
              showCopyModal={() => setShowCopyModal(true)}
              showShareModal={() => setShowShareModal(true)}
              canEdit={props.canEdit}
              editModeUrl={props.editModeUrl}
            /> : null
        }
      </div>
      <CopyModal />
      <ShareModal />
    </li>
  );
};

export default DashboardItem;
